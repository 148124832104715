<template>
  <div class="loginlog">
    <div v-show="loading" class="loading">
      <img src="@/assets/loading.gif" alt="" />
    </div>
    <div v-show="!loading">
      <div class="loginlog-title">
        <div class="title">用户登录日志</div>
        <div class="flex-space"></div>
        <!-- <div class="loginlog-fliter">
          <div v-for="(item, index) in filterList" :key="index" class="loginlog-fliter-item">
            {{ item.name }}:{{ item.value }}
            <i class="el-icon-delete" @click="deleteFilterItem(item.name)"></i>
          </div>
          <el-button @click="deleteAllFilter" type="text" v-if="filterList.length > 0">
            重置
          </el-button>
        </div> -->
      </div>
      <div v-if="filterList.length > 0" class="datalist-title-filter">
        <div v-for="(item, index) in filterList" :key="index" class="datalist-title-filter-item">
          {{ item.name }}: {{ item.value }}
          <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
        </div>
        <el-button
          v-if="filterList.length"
          class="datalist-title-button"
          type="text"
          @click="deleteAllFilter"
        >
          重置
        </el-button>
      </div>
      <eyao-table
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total-num="dataCount"
        ref="table"
        :height="'100%'"
        :height-sty="'85%'"
        :table-data="tableData"
        @filter-change="filterChange"
      >
        <el-table-column
          prop="login_time"
          column-key="login_time"
          label="登录时间"
          sortable
        ></el-table-column>
        <el-table-column prop="user_name" column-key="user_name" label="登录用户">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="user_name"
              title="登录用户"
              :scope="scope"
              @search="search"
            ></eyao-table-search-header>
          </template>
        </el-table-column>
        <el-table-column prop="login_ip" column-key="login_ip" label="登录ip">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="login_ip"
              title="登录ip"
              :scope="scope"
              @search="search"
            ></eyao-table-search-header>
          </template>
        </el-table-column>
        <el-table-column
          prop="device_type"
          column-key="device_type"
          label="设备类型"
          :filters="[{ text: 'PC', value: 'PC' }]"
          :filter-method="filterHandler"
        ></el-table-column>
        <el-table-column prop="device_name" column-key="device_name" label="设备名称">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="device_name"
              title="设备名称"
              :scope="scope"
              @search="search"
            ></eyao-table-search-header>
          </template>
        </el-table-column>
        <el-table-column prop="browser_type" column-key="browser_type" label="浏览器类型">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="browser_type"
              title="浏览器类型"
              :scope="scope"
              @search="search"
            ></eyao-table-search-header>
          </template>
        </el-table-column>
        <el-table-column prop="browser_version" column-key="browser_version" label="浏览器版本号">
          <template slot="header" slot-scope="scope">
            <eyao-table-search-header
              property="browser_version"
              title="浏览器版本号"
              :scope="scope"
              @search="search"
            ></eyao-table-search-header>
          </template>
        </el-table-column>
        <!-- <template v-slot:append>
        <div class="loginlog-loadmore" v-loading="needLoading">
          {{ needLoading ? '加载中' : '已显示全部数据' }}
        </div>
      </template> -->
        <template v-slot:empty>
          <div class="no_data" v-if="!tableData.length && !loading">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>
      <!-- <template v-if="!tableData.length && !loading">
        <div class="no_data">
          <img
            src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
            alt="未查询到相关数据"
          />
          未查询到相关数据
        </div>
      </template> -->
    </div>
  </div>
</template>
<script>
import store from '@/store';
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import { GetUserLoginLogList } from '@/api/log';
import { dateFormat } from '@/utils/util';
export default {
  name: 'LoginLog',
  components: {
    eyaoTable,
    eyaoTableSearchHeader,
  },
  data() {
    return {
      tableData: [],
      filterData: [],
      // 数据总量
      dataCount: 0,
      // 数据当前加载完成的页码
      dataIndex: 1,
      loading: true,
      filterObject: {},
      filterList: [],

      // req: {
      //   uin: '',
      //   page_size: 20,
      //   page: 1,
      // },
      pageSizes: [],
      pageSize: '',
      pageIndex: 1,
      current: 1,
    };
  },
  computed: {
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    needLoading() {
      return !(this.tableData.length === this.dataCount);
    },
  },
  watch: {
    role(newval) {
      if (newval !== 1) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
    $route(to) {
      if (to.name === 'login-log') {
        this.init();
      }
    },
  },
  created() {
    this.pageSize = this.g_pageSize;
    this.pageSizes = this.g_pageSizes;
  },
  mounted() {
    if (this.role !== 1) {
      this.$message.error('无权限进入');
      this.$router.push('/dashboard/home');
    }

    this.init();
  },
  methods: {
    handleSizeChange(val) {
      if (this.filterList.length > 0) {
        const req = {
          uin: '',
          page_size: val,
          page: this.pageIndex,
        };
        this.filterList.forEach(item => {
          if (item.name === '设备名称') {
            req.device_name = item.value;
          }
          if (item.name === '登录ip') {
            req.login_ip = item.value;
          }
          if (item.name === '浏览器类型') {
            req.browser_type = item.value;
          }
          if (item.name === '浏览器版本号') {
            req.browser_version = item.value;
          }
          if (item.name === '登录用户') {
            req.user_name = item.value;
          }
        });
        GetUserLoginLogList(req).then(res => {
          this.tableData =
            res.user_login_log_list?.map(item => {
              return {
                ...item,
                login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
              };
            }) || [];
          this.dataCount = res.total_num;
          // this.dataIndex += 1;
          this.loading = false;
        });
      } else {
        this.pageSize = val;
        // this.init();
        const req = {
          uin: '',
          page_size: this.pageSize,
          page: this.pageIndex,
        };
        GetUserLoginLogList(req).then(res => {
          this.tableData =
            res.user_login_log_list?.map(item => {
              return {
                ...item,
                login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
              };
            }) || [];
          this.dataCount = res.total_num;
          // this.dataIndex += 1;
          this.loading = false;
        });
      }
    },
    handleCurrentChange(val) {
      this.loading = true;
      if (this.filterList.length > 0) {
        const req = {
          uin: '',
          page_size: this.pageSize,
          page: val,
        };
        this.filterList.forEach(item => {
          if (item.name === '设备名称') {
            req.device_name = item.value;
          }
          if (item.name === '登录ip') {
            req.login_ip = item.value;
          }
          if (item.name === '浏览器类型') {
            req.browser_type = item.value;
          }
          if (item.name === '浏览器版本号') {
            req.browser_version = item.value;
          }
          if (item.name === '登录用户') {
            req.user_name = item.value;
          }
        });
        GetUserLoginLogList(req).then(res => {
          this.tableData =
            res.user_login_log_list?.map(item => {
              return {
                ...item,
                login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
              };
            }) || [];
          this.dataCount = res.total_num;
          // this.dataIndex += 1;
          this.loading = false;
        });
      } else {
        this.pageIndex = val;
        // this.init();
        const req = {
          uin: '',
          page_size: this.pageSize,
          page: this.pageIndex,
        };
        GetUserLoginLogList(req).then(res => {
          this.tableData =
            res.user_login_log_list?.map(item => {
              return {
                ...item,
                login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
              };
            }) || [];
          this.dataCount = res.total_num;
          // this.dataIndex += 1;
          this.loading = false;
        });
      }
    },

    init() {
      this.loading = true;
      const req = {
        uin: this.$route.params.uin ? String(this.$route.params.uin) : '',
        page_size: this.pageSize,
        page: this.pageIndex,
      };
      GetUserLoginLogList(req)
        .then(res => {
          this.tableData =
            res.user_login_log_list?.map(item => {
              return {
                ...item,
                login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
              };
            }) || [];
          this.dataCount = res.total_num;
          this.dataIndex = 2;
          this.loading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
          this.loading = false;
        });
      const { breadcrumb } = store.state.user;
      breadcrumb.push({
        name: '用户登陆日志',
        path: '/dashboard/login-log',
        params: { uin: this.$route.params.uin },
      });
      store.commit('user/setBreadcrumb', breadcrumb);

      if (JSON.stringify(this.$route.params) !== '{}') {
        console.log(this.$route.params);
        this.filterChange({ 登录用户: [this.$route.params.name] });
      }
    },
    // dataLoading() {
    //   // 不重复发送加载请求
    //   if (this.dataCount !== this.tableData.length && this.loading !== false) {
    //     return;
    //   }
    //   this.loading = true;
    //   let req = {
    //     uin: '',
    //     page_size: this.g_pageSize,
    //     page: this.dataIndex,
    //   };
    //   if (this.filterList.length < 1) {
    //     req = {
    //       uin: '',
    //       page_size: this.g_pageSize,
    //       page: this.dataIndex,
    //     };
    //   }
    //   this.filterList.forEach((item) => {
    //     if (item.name === '设备名称') {
    //       req.device_name = item.value;
    //     }
    //     if (item.name === '登录ip') {
    //       req.login_ip = item.value;
    //     }
    //     if (item.name === '浏览器类型') {
    //       req.browser_type = item.value;
    //     }
    //     if (item.name === '浏览器版本号') {
    //       req.browser_version = item.value;
    //     }
    //     if (item.name === '登录用户') {
    //       req.user_name = item.value;
    //     }
    //   });
    //   GetUserLoginLogList(req).then((res) => {
    //     this.tableData = this.tableData.concat(
    //       res.UserLoginLogList.map((item) => {
    //         return {
    //           ...item,
    //           login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
    //         };
    //       }),
    //     );
    //     this.dataCount = res.total_num;
    //     this.dataIndex += 1;
    //     this.loading = false;
    //   });
    // },
    search(item, item2) {
      this.dataCount = 0;
      this.current = 1;
      // console.log(this.dataIndex);
      // console.log(item, item2);
      const options = {
        login_ip: '登录ip',
        browser_type: '浏览器类型',
        browser_version: '浏览器版本号',
        device_name: '设备名称',
        // device_type: '设备类型'
        user_name: '登录用户',
      };
      if (item2 !== '') {
        const key = options[item];
        const obj = {};
        obj[key] = [item2];
        this.filterChange(obj);
      }
      console.log(this.filterList);
      let req = {
        uin: '',
        page_size: this.g_pageSize,
        page: 1,
      };
      if (this.filterList.length < 1) {
        req = {
          uin: '',
          page_size: this.g_pageSize,
          page: 1,
        };
      }
      if (this.filterList.length > 0) {
        this.filterList.forEach(item => {
          if (item.name === '设备名称') {
            req.device_name = item.value;
          }
          if (item.name === '登录ip') {
            req.login_ip = item.value;
          }
          if (item.name === '浏览器类型') {
            req.browser_type = item.value;
          }
          if (item.name === '浏览器版本号') {
            req.browser_version = item.value;
          }
          if (item.name === '登录用户') {
            req.user_name = item.value;
          }
        });
      }
      this.current = 1;
      GetUserLoginLogList(req).then(res => {
        this.tableData =
          res.user_login_log_list?.map(item => {
            return {
              ...item,
              login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
            };
          }) || [];
        this.dataCount = res.total_num;
        // this.dataIndex = 2;
        this.loading = false;
      });
    },
    filterHandler(value, row, column) {
      console.log(value, row, column);
      const { property } = column;
      return row[property] === value;
    },
    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      console.log('筛选', filter);
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
    },
    updateFilterList() {
      const list = [];
      Object.keys(this.filterObject).forEach(key => {
        if (this.filterObject[key].length !== 0 && key !== 'device_type') {
          list.push({ name: key, value: this.filterObject[key].join(' ') });
        }
      });
      this.filterList = list;
      console.log(this.filterList);
    },
    // 筛选条件被手动删除时调用table方法更新table内筛选信息
    deleteFilterItem(key, delay = false) {
      this.filterObject[key] = [];
      this.$refs.table.clearFilter([key]);

      this.updateFilterList();
      console.log('剩下', this.filterList);
      if (!delay) {
        this.dataCount = 0;
        let req = {
          uin: '',
          page_size: this.g_pageSize,
          page: 1,
        };
        if (this.filterList.length < 1) {
          req = {
            uin: '',
            page_size: this.g_pageSize,
            page: 1,
          };
        }
        this.filterList.forEach(item => {
          if (item.name === '设备名称') {
            req.device_name = item.value;
          }
          if (item.name === '登录ip') {
            req.login_ip = item.value;
          }
          if (item.name === '浏览器类型') {
            req.browser_type = item.value;
          }
          if (item.name === '浏览器版本号') {
            req.browser_version = item.value;
          }
          if (item.name === '登录用户') {
            req.user_name = item.value;
          }
        });
        console.log('删除后的', req);
        GetUserLoginLogList(req).then(res => {
          this.tableData =
            res.user_login_log_list?.map(item => {
              return {
                ...item,
                login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
              };
            }) || [];
          this.dataCount = res.total_num;
          this.dataIndex = 2;
        });
      }
    },
    deleteAllFilter() {
      this.dataCount = 0;
      Object.keys(this.filterObject).forEach(key => {
        this.deleteFilterItem(key, true);
      });
      const req = {
        uin: '',
        page_size: this.g_pageSize,
        page: 1,
      };
      GetUserLoginLogList(req).then(res => {
        this.tableData =
          res.user_login_log_list?.map(item => {
            return {
              ...item,
              login_time: dateFormat(new Date(item.login_time * 1000), 'YYYY-MM-dd HH:mm:SS'),
            };
          }) || [];
        this.dataCount = res.total_num;
        this.dataIndex = 2;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './login-log.scss';
</style>
